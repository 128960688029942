<template>
  <div class="busStop">
    <div class="busStop-t">
      <div class="map" @click="tomap">
        <h3>五江口地图</h3>
        <p>快速定位轻松到达</p>
      </div>
      <div class="weather" v-if="weather.weather">
        <img
          v-if="weather.weather"
          :src="require('./img/' + returnStatus(weather.weather) + '.png')"
          alt=""
        />
        <h3>{{ weather.weather }} {{ weather.temperature }}℃</h3>
        <p>{{ weather.province }}-{{ weather.city }}</p>
        <p>{{ weather.reporttime }}</p>
      </div>
    </div>
    <div class="busStop-b">
      <div class="title">
        <div class="title-l"><img src="./img/busStation.png" alt="" /></div>
        <div class="title-r">附近公交站</div>
      </div>
      <div class="main">
        <div class="item" v-for="(item, index) in stationInfo" :key="index">
          <div class="item-body" v-if="item.isshow" @click="toDetail(item)">
            <div class="item-t">
              <div class="item-tl">
                <div class="item-tll">{{ item.routeName }}</div>
                <div class="item-tlr">
                  <div class="name">{{ item.originStation }}</div>
                  <div class="img"><img src="./img/right.png" alt="" /></div>
                  <div class="name">{{ item.finishStation }}</div>
                </div>
              </div>
              <div class="item-tr" @click.stop="switchDirection(item)">
                <div class="img"><img src="./img/double.png" alt="" /></div>
                <div class="operate">换向</div>
              </div>
            </div>
            <div class="item-c">
              <div class="item-cl">
                <div class="img"><img src="./img/bus.png" alt="" /></div>
                <div class="txt">{{ item.stationName }}</div>
              </div>
              <div class="item-cr">
                <div class="img"><img src="./img/navigation.png" alt="" /></div>
              </div>
            </div>
            <div class="item-b">
              <div class="item-bc" v-if="item.busState == '即将到站'">
                {{ item.busState }}
              </div>
              <div
                class="item-bc"
                v-else-if="item.busState.indexOf('下一班') != -1"
              >
                {{ item.busState }}
              </div>
              <div
                class="item-bc"
                v-else-if="item.busState.indexOf('前方无车') != -1"
              >
                {{ item.busState }}
              </div>
              <div class="item-bc" v-else>
                <div class="item-bcl">
                  距<span>{{ item.stationName }}</span
                  >最近1班：
                </div>
                <div class="item-bcr">
                  还有<span>{{
                    item.busState && item.busState.split(",")
                      ? item.busState.split(",")[0]
                      : ""
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { busStopUrl, getWeatherUrl } from "./api.js";
import wx from "weixin-js-sdk";
export default {
  name: "busStop",
  data() {
    return {
      busStop: {},
      stationInfo: [],
      adcode: "",
      weather: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    assetId() {
      return this.$store.state.assetId;
    },
  },
  async mounted() {
    this.getBusStop();
  },
  created() {
    this.adcode = this.$route.query.adcode;
    if (this.adcode) {
      this.getWeather();
    }
  },
  methods: {
    tomap() {
      wx.miniProgram.navigateTo({
        url: `/subPages/map/index`,
      });
    },
    returnStatus(weather) {
      if (weather) {
        if (weather.indexOf("晴") != -1) {
          return "sunny";
        } else if (
          weather.indexOf("大雨") != -1 ||
          weather.indexOf("暴雨") != -1 ||
          weather.indexOf("阵雨") != -1
        ) {
          return "da-rain";
        } else if (weather.indexOf("中雨") != -1) {
          return "zhong-rain";
        } else if (
          weather.indexOf("小雨") != -1 ||
          weather.indexOf("雨") != -1
        ) {
          return "xiao-rain";
        } else {
          return "cloudy";
        }
      }
    },
    getWeather() {
      let params = {
        cityCode: this.adcode,
      };
      this.$axios.get(`${getWeatherUrl}`, { params }).then((res) => {
        if (res.code == 200) {
          let obj = JSON.parse(res.data);
          if (obj.lives && obj.lives[0]) {
            this.weather = obj.lives[0];
            console.log(this.weather.weather.indexOf("晴"));
          }
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "busStopDetail",
        query: {
          routeName: item.routeName,
          lineType: item.lineType,
        },
      });
    },
    switchDirection(item) {
      item.isshow = !item.isshow;
      this.stationInfo.forEach((oItem) => {
        if (
          item.lineType != oItem.lineType &&
          oItem.routeName == item.routeName
        ) {
          oItem.isshow = !oItem.isshow;
        }
      });
      this.$forceUpdate();
    },
    getBusStop() {
      this.$axios.get(`${busStopUrl}`).then((res) => {
        if (res.code === 200) {
          this.busStop = res.data;
          if (this.busStop.stationInfo) {
            this.stationInfo = this.busStop.stationInfo;
            this.stationInfo.forEach((item) => {
              item.isshow = false;
              if (item.lineType == "forward_line") {
                item.isshow = true;
              }
            });
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.busStop {
  background: url("./img/back.png") no-repeat;
  background-size: 100% 199px;
  padding: 39px 26px;
  box-sizing: border-box;
  .busStop-t {
    height: 175px;
    display: flex;
    justify-content: space-between;
    .map,
    .weather {
      width: 332px;
      height: 175px;
      border-radius: 8px;
      padding: 31px 0 0 18px;
      box-sizing: border-box;
      box-shadow: 0px 4px 58px 4px rgba(0, 0, 0, 0.1);
      h3 {
        font-size: 30px;
        font-weight: 700;
        color: #000000;
        line-height: 32px;
        margin-bottom: 50px;
      }
      p {
        font-size: 28px;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
      }
    }
    .weather {
      position: relative;
      img {
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      h3 {
        margin-bottom: 27px;
      }
      h3,
      p {
        position: relative;
        z-index: 9;
      }
    }
    .map {
      background: url("./img/map.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .busStop-b {
    .title {
      margin: 35px 0 11px;
      display: flex;
      align-items: center;
      .title-l {
        width: 64px;
        height: 56px;
        margin-right: 23px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title-r {
        font-size: 36px;
        font-weight: 400;
        color: #000000;
        line-height: 40px;
      }
    }
    .main {
      .item-body {
        background: #ffffff;
        box-shadow: 0px 4px 58px 4px #f5f7f9;
        border-radius: 8px;
        margin-bottom: 70px;
        padding: 41px 22px 0 22px;
        box-sizing: border-box;
        .item-t {
          display: flex;
          justify-content: space-between;
          margin-bottom: 18px;
          .item-tl {
            display: flex;
            .item-tlr {
              display: flex;
              align-items: center;
              .name {
                font-size: 26px;
                font-weight: 400;
                color: #000000;
                line-height: 28px;
                max-width: 163px;
              }
              .img {
                margin: 0 10px;
                width: 38px;
                height: 14px;
                font-size: 0;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .item-tll {
              font-size: 36px;
              font-weight: 700;
              color: #000000;
              line-height: 40px;
              margin-right: 40px;
            }
          }
          .item-tr {
            display: flex;
            align-items: center;
            .img {
              width: 36px;
              font-size: 0;
              height: 20px;
              margin-right: 12px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .operate {
              font-size: 24px;
              font-weight: bold;
              color: #000000;
              line-height: 40px;
            }
          }
        }
        .item-c {
          display: flex;
          justify-content: space-between;
          padding-bottom: 35px;
          border-bottom: 1px solid #f5f5f5;

          .item-cl {
            display: flex;
            align-items: center;
            .img {
              width: 46px;
              height: 54px;
              margin-right: 26px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              font-size: 32px;
              font-weight: 500;
              color: #000000;
              line-height: 40px;
            }
          }
          .item-cr {
            width: 58px;
            height: 58px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .item-b {
          padding: 30px 0px 10px;
          .item-bc {
            display: flex;
            justify-content: space-between;
            font-size: 28px;
            font-weight: 400;
            color: #000000;
            line-height: 40px;
            .item-bcl,
            .item-bcr {
              span {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.busStop {
}
</style>
